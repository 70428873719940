<script setup>
import { useI18n } from "vue-i18n";

const { locale } = useI18n();

const { isLabelTranslationsFetched, getLabelTranslation } =
  useLabelTranslation();

const props = defineProps({
  wine: {
    type: Object,
    required: true,
  },
  brand: {
    type: Object,
    required: true,
  },
  title: {
    type: String,
    required: false,
  },
  subtitle: {
    type: String,
    required: false,
  },
  greyBackground: {
    type: Boolean,
  },
  smallValues: {
    type: Boolean,
  },
  showServingSize: {
    type: Boolean,
  },
});

const energyLabel = computed(() => {
  const kcalLabel = props.wine.energy_kcal
    ? `${parseFloat(props.wine.energy_kcal).toLocaleString(locale.value)} kcal`
    : undefined;

  const kjLabel = props.wine.energy_kj
    ? `${parseFloat(props.wine.energy_kj).toLocaleString(locale.value)} kJ`
    : undefined;

  const labels = [kjLabel, kcalLabel].filter((ele) => !!ele);

  return labels.length ? labels.join(" / ") : "-";
});

const zeroLabel = computed(() => {
  if (props.brand.displaySmallValues === "zero-grams") {
    return "0 g";
  }

  return getLabelTranslation("t_negligible");
});

// Take a per liter value. Format for
const formatValue = (value) => {
  if (value > 0) {
    return `${(value / 10).toLocaleString(locale.value, {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    })} g`;
  }

  return zeroLabel.value;
};
</script>

<template>
  <div
    v-if="isLabelTranslationsFetched"
    :class="{
      'nutritional-info-table': true,
      'nutritional-info-table--grey': greyBackground,
      'nutritional-info-table--small-values': smallValues,
    }"
  >
    <div class="table-title" v-if="title">{{ title }}</div>
    <div class="section">
      <div class="row">
        <div class="label">{{ getLabelTranslation("t_energy") }}</div>
        <div class="value">{{ energyLabel }}</div>
      </div>
    </div>

    <div class="section">
      <div class="row">
        <div class="label">{{ getLabelTranslation("t_fat") }}</div>
        <div class="value">{{ formatValue(props.wine.fat) }}</div>
      </div>
      <div class="row">
        <div class="label">{{ getLabelTranslation("t_which_saturate") }}</div>
        <div class="value value--small">
          {{ formatValue(props.wine.saturated_fat) }}
        </div>
      </div>
    </div>

    <div class="section">
      <div class="row">
        <div class="label">{{ getLabelTranslation("t_carbo") }}</div>
        <div class="value">
          {{ formatValue(props.wine.carbohydrate || props.wine.sugar) }}
        </div>
      </div>
      <div class="row">
        <div class="label">{{ getLabelTranslation("t_which_sugar") }}</div>
        <div class="value value--small">
          {{ formatValue(props.wine.sugar) }}
        </div>
      </div>
    </div>

    <div class="section">
      <div class="row">
        <div class="label">{{ getLabelTranslation("t_prot") }}</div>
        <div class="value">{{ formatValue(props.wine.protein) }}</div>
      </div>
    </div>
    <div class="section">
      <div class="row">
        <div class="label">{{ getLabelTranslation("t_salt") }}</div>
        <div class="value">{{ formatValue(props.wine.salt) }}</div>
      </div>
    </div>

    <span class="subtitle" v-if="showServingSize">{{
      getLabelTranslation("t_based_100")
    }}</span>
  </div>
</template>

<style scoped>
.nutritional-info-table {
  color: #121212;
  margin: 20px 0;
  background-color: white;
  padding: 20px 40px;
  border-radius: 25px;
}

.nutritional-info-table--grey {
  background-color: #fafafb;
}

.nutritional-info-table--small-values .value {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

.table-title {
  font-size: 16px;
  font-weight: 900;
  line-height: 20px;
}

.section {
  padding: 22px 0 7px 0;
}

.section:not(:last-of-type) {
  border-bottom: 1px solid #dddddd;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
}

@media screen and (max-width: 600px) {
  .row {
    flex-direction: column;
    align-items: flex-start;
  }
}

.label {
  font-size: 14px;
  font-weight: bold;
  line-height: 22px;
}

.value {
  text-align: end;
  font-size: 22px;
  font-weight: 400;
  line-height: 26px;
}

.value--small {
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
}

@media screen and (max-width: 600px) {
  .value {
    font-size: 14px;
    line-height: 22px;
    text-align: start;
  }
}

.subtitle {
  display: block;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: #777777;
}
</style>
